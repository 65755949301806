import React from "react";
import {
  Container,
  InternalContainer,
  PossibilitiesContainer,
  TitleContainer,
  LogosContainer,
  Logo,
} from "./styles";

const Possibilities: React.FC = () => {
  return (
    <Container>
      <InternalContainer>
        <PossibilitiesContainer>
          <TitleContainer>
            VEJA AS POSSIBILIDADES PARA
            <br /> O SEU FINANCIAMENTO
          </TitleContainer>
          <LogosContainer>
            <Logo
              src="/imgs/possibilities/solfacil.png"
              style={{ paddingRight: 20 }}
            />
            <Logo
              src="/imgs/possibilities/santander.png"
              style={{ paddingLeft: 20 }}
            />
          </LogosContainer>
          <LogosContainer style={{ paddingBottom: 60 }}>
            <Logo
              src="/imgs/possibilities/porto.png"
              style={{ paddingRight: 20 }}
            />
            <Logo
              src="/imgs/possibilities/bndes.png"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            />
            <Logo
              src="/imgs/possibilities/bv.png"
              style={{ paddingLeft: 20 }}
            />
          </LogosContainer>
        </PossibilitiesContainer>
      </InternalContainer>
    </Container>
  );
};

export default Possibilities;
