import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid #f3ea27;
  background-color: #29304a;
`;

export const ServicesTitle = styled.div`
  width: 100%;
  padding: 70px 0px;
  font-weight: bold;
  display: flex;
  color: #f3ea27;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
  font-size: 44px;
  @media (max-width: 800px) {
    font-size: 36px;
  }
`;

export const AboutText = styled.div`
  width: 50%;
  text-align: justify;
  font-size: 24px;
  line-height: 1.6;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const Card = styled.div`
  width: 50%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  @media (max-width: 1600px) {
    width: 60%;
  }
  @media (max-width: 1366px) {
    width: 70%;
  }
  @media (max-width: 1366px) {
    width: 80%;
  }
  @media (max-width: 800px) {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    width: 90%;
    flex-direction: column;
  }
`;

export const CardImage = styled.img`
  width: 35%;
  margin-left: -5%;
  margin-top: -20px;
  margin-bottom: -20px;
  @media (max-width: 980px) {
    width: 40%;
    margin: -40px 0 -40px -5%;
  }
  @media (max-width: 800px) {
    margin-left: 0;
    margin: -50px 0 0 0;
    flex-direction: column;
  }
`;

export const CardText = styled.div`
  width: 100%;
  font-size: 18px;
  color: #29304a;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  line-height: 2;
  padding: 20px 0 20px 60px;
  @media (max-width: 800px) {
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 20px 0 40px 0;
  }
`;

export const CardTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

export const ContentButton = styled.div`
  margin: -20px 5% 70px 0;
  cursor: pointer;
  background-color: #f3ea27;
  border-radius: 10px;
  color: #29304a;
  padding: 15px 25px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 800px) {
    text-align: center;
    margin: -20px 0px 0px 0px;
  }
`;

export const ContentBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    padding: 0 0 100px 0;
    justify-content: center;
    width: 100%;
  }
`;
