import React from "react";
import { FaYoutube, FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import { Container, SocialButton } from "./styles";

const SocialBar: React.FC = () => {
  return (
    <Container>
      <SocialButton
        style={{ fontSize: 28 }}
        onClick={() =>
          window.open(
            "https://www.youtube.com/channel/UCQhvr-0bh6SuKWWnkflTTqg",
            "_blank"
          )
        }
      >
        <FaYoutube />
      </SocialButton>
      <SocialButton
        style={{ fontSize: 26 }}
        onClick={() =>
          window.open(
            "https://wa.me/5521998609818?&text=Olá,%20tudo%20bem?%20Olhei%20o%20sites%20de%20vocês%20e%20gostaria%20de%20mais%20informações",
            "_blank"
          )
        }
      >
        <FaWhatsapp />
      </SocialButton>
      <SocialButton
        style={{ fontSize: 26 }}
        onClick={() =>
          window.open("https://www.instagram.com/sustengtec/", "_blank")
        }
      >
        <FaInstagram />
      </SocialButton>
      <SocialButton
        style={{ marginRight: "5%", fontSize: 23 }}
        onClick={() =>
          window.open("https://facebook.com/sustengtec/", "_blank")
        }
      >
        <FaFacebook />
      </SocialButton>
    </Container>
  );
};

export default SocialBar;
