import React, { useState } from "react";
import {
  Container,
  AboutTitle,
  AboutText,
  ImageBanner,
  ArrowBox,
} from "./styles";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { FaInstagram, FaFacebook } from "react-icons/fa";

interface PortfolioProps {
  path: string;
  count: number;
  backgroundColor: string;
  borderBottom: boolean;
  fontColor: string;
  imgFormat: string;
}

const Portfolio: React.FC<PortfolioProps> = ({
  path,
  count,
  backgroundColor,
  borderBottom,
  fontColor,
  imgFormat,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const previousImage = () =>
    currentImage == 0
      ? setCurrentImage(count)
      : setCurrentImage(currentImage - 1);
  const nextImage = () =>
    currentImage == count
      ? setCurrentImage(0)
      : setCurrentImage(currentImage + 1);
  return (
    <Container
      fontColor={fontColor}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
    >
      <AboutTitle>
        VEJA O RESULTADO FINAL DE UM
        <br />
        DOS NOSSOS PROJETOS
      </AboutTitle>
      <ImageBanner src={`${path}/${currentImage}.${imgFormat}`} />
      <ArrowBox>
        <BsFillArrowLeftCircleFill
          onClick={() => previousImage()}
          style={{ fontSize: 50, paddingRight: 5, cursor: "pointer" }}
        />
        <BsFillArrowRightCircleFill
          onClick={() => nextImage()}
          style={{ fontSize: 50, paddingLeft: 5, cursor: "pointer" }}
        />
      </ArrowBox>
      <AboutText>Veja outros projetos nas redes sociais</AboutText>
      <ArrowBox style={{ paddingBottom: 30 }}>
        <FaInstagram
          onClick={() =>
            window.open("https://www.instagram.com/sustengtec/", "_blank")
          }
          style={{ fontSize: 50, paddingRight: 15, cursor: "pointer" }}
        />
        <FaFacebook
          onClick={() =>
            window.open("https://facebook.com/sustengtec/", "_blank")
          }
          style={{ fontSize: 50, paddingLeft: 15, cursor: "pointer" }}
        />
      </ArrowBox>
    </Container>
  );
};

export default Portfolio;
