import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #f3ea27;
  padding: 60px 0px;
`;

export const InternalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 44px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding: 0px 0px 30px 0px;
  color: #29304a;
  width: 100%;
`;

export const ContactContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ContactCard = styled.div`
  width: 45%;
  color: #29304a;
  font-size: 22px;
  text-align: center;
  @media (max-width: 800px) {
    width: 80%;
    padding: 20px 0px;
  }
`;

export const ProfilePicture = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50px;
`;
