import React from "react";
import { openWhats } from "../../utils/whats";
import { BannerTitle, Container, BannerButton } from "./styles";

interface BannerInterface {
  text: string;
  call_to_action: string;
  bg: string;
}

interface BannerProps {
  banner: BannerInterface;
}

const Banner: React.FC<BannerProps> = (props) => {
  return (
    <Container url={props.banner.bg}>
      <BannerTitle>{props.banner.text}</BannerTitle>
      <BannerButton onClick={() => openWhats()}>
        {props.banner.call_to_action}
      </BannerButton>
    </Container>
  );
};

export default Banner;
