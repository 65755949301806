import React from "react";
import { openWhats } from "../../utils/whats";
import { BannerButton } from "../banner/styles";
import { Container, InternalContainer } from "./styles";

const YellowBanner: React.FC = () => {
  return (
    <Container>
      <InternalContainer>
        Ficou interessado?
        <br />
        <br />
        Faça o seu orçamento agora
        <br />
        mesmos em compromisso
        <BannerButton
          onClick={() => openWhats()}
          style={{ marginTop: 30, marginBottom: 0 }}
        >
          SOLICITAR ORÇAMENTO
        </BannerButton>
      </InternalContainer>
    </Container>
  );
};

export default YellowBanner;
