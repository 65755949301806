import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import {
  ContactContainer,
  Container,
  InternalContainer,
  Title,
  ContactCard,
  ProfilePicture,
} from "./styles";

const Contact: React.FC = () => {
  return (
    <Container>
      <InternalContainer>
        <Title>CONTATOS</Title>
        <ContactContainer>
          <ContactCard>
            <ProfilePicture src="/imgs/profile/2.jpeg" />
            <br />
            <br />
            Pedro Gonçalves Righetti
            <br />
            <br />
            Engenheiro Ambiental
            <br />
            <br />
            CREA/SP - 5062820130
            <br />
            <br />
            <BsFillTelephoneFill style={{ fontSize: 18, paddingRight: 5 }} />
            (21) 99860-9818
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoMdMail style={{ paddingRight: 5 }} /> pedro@sustengtec.com
            </div>
          </ContactCard>
          <ContactCard>
            <ProfilePicture src="/imgs/profile/1.jpeg" />
            <br />
            <br />
            Felipe Ernani Lancieri Fernandes
            <br />
            <br />
            Engenheiro Civil
            <br />
            <br />
            CREA/SP - 5069372420
            <br />
            <br />
            <BsFillTelephoneFill style={{ fontSize: 18, paddingRight: 5 }} />
            (13) 97408-0406
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoMdMail style={{ paddingRight: 5 }} /> felipe@sustengtec.com
            </div>
          </ContactCard>
        </ContactContainer>
      </InternalContainer>
    </Container>
  );
};

export default Contact;
