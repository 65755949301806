import React from "react";
import { openWhats } from "../../utils/whats";
import {
  Container,
  InternalContainer,
  ProductImage,
  ProductContainer,
  ProductDescription,
  YellowBorder,
  Content,
  ContentImage,
  ContentTextBox,
  ContentButton,
} from "./styles";

const navigate = (route: string) => {
  window.location.href = route;
};

const Products: React.FC = () => {
  return (
    <Container>
      <InternalContainer>
        <ProductContainer onClick={() => navigate("/civil")}>
          <ProductImage src="/imgs/products/civil.png" alt="" />
          <ProductDescription>
            ENGENHARIA
            <br />
            CIVIL
          </ProductDescription>
        </ProductContainer>
        <YellowBorder />
        <ProductContainer onClick={() => navigate("/ambiental")}>
          <ProductImage src="/imgs/products/ambiental.png" alt="" />
          <ProductDescription>
            ENGENHARIA
            <br />
            AMBIENTAL
          </ProductDescription>
        </ProductContainer>
        <YellowBorder />
        <ProductContainer onClick={() => navigate("/solar")}>
          <ProductImage src="/imgs/products/solar.png" alt="" />
          <ProductDescription>
            ENERGIA SOLAR
            <br />
            FOTOVOLTAICA
          </ProductDescription>
        </ProductContainer>
      </InternalContainer>
      <Content>
        <ContentImage src="/imgs/products/bg.png" alt=" " />
        <ContentTextBox>
          Promova a diferença que você quer ver no mundo, ajudamos você a ser
          ecologicamente correto!
          <ContentButton onClick={() => openWhats()}>
            Quero saber mais
          </ContentButton>
        </ContentTextBox>
      </Content>
    </Container>
  );
};

export default Products;
