import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 0 0 0;
  background-color: #d8e0e4;
  border-bottom: 5px solid #f3ea27;
`;

export const InternalContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  max-width: 600px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  margin-top: -30px;
  @media (max-width: 1080px) {
    width: 95%;
    margin-top: 20px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
`;

export const ProductContainer = styled.div`
  width: 150px;
  padding: 15px;
  height: auto;
  height: 150px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 60%;
`;

export const ProductDescription = styled.div`
  padding: 15px 0 0 0;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #29304a;
`;

export const YellowBorder = styled.div`
  height: 80px;
  width: 2px;
  background-color: #f3ea27;
  @media (max-width: 560px) {
    width: 80px;
    height: 2px;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ContentImage = styled.img`
  width: 26%;
  @media (max-width: 1366px) {
    width: 35%;
  }
  @media (max-width: 1280px) {
    width: 40%;
  }
  @media (max-width: 1080px) {
    width: 50%;
  }
  @media (max-width: 800px) {
    width: 70%;
  }
`;

export const ContentTextBox = styled.div`
  width: 20%;
  line-height: 1.6;
  padding: 0 0 0 30px;
  font-size: 30px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #29304a;
  @media (max-width: 1280px) {
    width: 40%;
  }
  @media (max-width: 800px) {
    padding: 30px 0 0 0;
    width: 70%;
  }
`;

export const ContentButton = styled.div`
  margin: 10px 0 0 0;
  width: 80%;
  cursor: pointer;
  background-color: #f3ea27;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
`;
