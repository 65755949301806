import React from "react";
import parse from "html-react-parser";
import {
  Container,
  InternalContainer,
  Title,
  Text,
  WhiteTitle,
  ImageBox,
  Image,
} from "./styles";

enum types {
  TITLE,
  WHITETITLE,
  IMAGE,
  TEXT,
}

interface ContentTypes {
  type: types;
  value: string;
}

interface ContentProps {
  content: Array<ContentTypes>;
}

const Content: React.FC<ContentProps> = ({ content }) => {
  return (
    <Container>
      <InternalContainer>
        {content.map((item) => {
          if (item.type === types.TITLE)
            return <Title>{parse(item.value)}</Title>;
          if (item.type === types.TEXT) return <Text>{parse(item.value)}</Text>;
          if (item.type === types.IMAGE)
            return (
              <ImageBox>
                <Image src={item.value} />
              </ImageBox>
            );
          if (item.type === types.WHITETITLE)
            return <WhiteTitle>{parse(item.value)}</WhiteTitle>;
        })}
      </InternalContainer>
    </Container>
  );
};

export default Content;
