import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: #29304a;
`;

export const InternalContainer = styled.div`
  width: 100%;
  padding: 60px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  color: #fff;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  width: 60%;
  color: #f3ea27;
  @media (max-width: 1080px) {
    width: 90%;
  }
`;

export const Text = styled.div`
  padding: 0 0 30px 0;
  width: 60%;
  line-height: 1.5;
  color: #fff;
  font-size: 20px;
  @media (max-width: 1080px) {
    width: 90%;
  }
`;
export const ImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 30px 0;
`;
export const Image = styled.img`
  width: 60%;
  @media (max-width: 1080px) {
    width: 95%;
  }
`;

export const WhiteTitle = styled.div`
  width: 100%;
  font-size: 22px;
  color: #fff;
  text-align: center;
  display: flex;
  font-weight: bold;
  padding: 30px 0 60px 0;
  justify-content: center;
`;
