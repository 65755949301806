import styled from "styled-components";

interface ContainerProps {
  backgroundColor: string;
  borderBottom: boolean;
  fontColor: string;
}

export const Container = styled.div<
  Pick<ContainerProps, "backgroundColor" | "borderBottom" | "fontColor">
>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-bottom: ${(props) =>
    props.borderBottom ? "5px solid #f3ea27" : "none"};
  color: ${(props) => props.fontColor};
`;

export const AboutTitle = styled.div`
  width: 100%;
  padding: 0px 0px 50px 0px;
  font-weight: bold;
  display: flex;
  line-height: 1.6;
  text-align: center;
  justify-content: center;
  font-size: 44px;
  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

export const ArrowBox = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

export const ImageBanner = styled.img`
  height: 80%;
  border-radius: 15px;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const AboutText = styled.div`
  width: 50%;
  text-align: center;
  padding: 20px 0 0 0;
  font-size: 24px;
  line-height: 1.6;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
