import React from "react";
import {
  Card,
  CardImage,
  ContentButton,
  CardText,
  CardTitle,
  ContentBox,
} from "./styles";

interface Service {
  img: string;
  title: string;
  topics: Array<string>;
  url: string;
}

interface ServiceProps {
  service: Service;
}

const ServiceCard: React.FC<ServiceProps> = (props) => {
  return (
    <>
      <Card>
        <CardImage src={props.service.img} />
        <CardText>
          <CardTitle>{props.service.title}</CardTitle>
          {props.service.topics.map((topic) => (
            <>
              • {topic}
              <br />
            </>
          ))}
        </CardText>
      </Card>
      <ContentBox>
        <ContentButton
          onClick={() => (window.location.href = props.service.url)}
        >
          Quero saber mais
        </ContentButton>
      </ContentBox>
    </>
  );
};

export default ServiceCard;
