import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #d8e0e4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 5px 0 0 0;
`;

export const SocialButton = styled.div`
  font-size: 24px;
  padding: 0 10px 0 0;
  cursor: pointer;
  color: #29304a;
`;
