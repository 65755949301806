/* eslint-disable no-multi-str */
import React from "react";
import Content from "../../components/content";
import Footer from "../../components/footer";
import HeaderSub from "../../components/header-sub";
import SocialBar from "../../components/socialBar";
import YellowBanner from "../../components/yellow-banner";

enum types {
  TITLE,
  WHITETITLE,
  IMAGE,
  TEXT,
}

interface ContentTypes {
  type: types;
  value: string;
}

const content: Array<ContentTypes> = [
  {
    type: types.TITLE,
    value: "Estudo de Viabilidade:",
  },
  {
    type: types.TEXT,
    value:
      "Elaboramos um estudo detalhado para atender as necessidades de cada cliente.",
  },
  {
    type: types.TITLE,
    value: "Instalação e acompanhamento:",
  },
  {
    type: types.TEXT,
    value:
      "Garantimos total segurança e eficiência em seu projeto, monitoramento do sistema em tempo real disponível em App.",
  },
  {
    type: types.TITLE,
    value: "Manutenção preventiva:",
  },
  {
    type: types.TEXT,
    value:
      "Para garantir maior eficiência do sistema, disponibilizamos planos de ações preventivas.",
  },
  {
    type: types.WHITETITLE,
    value:
      "Veja a redução no valor da conta de energia após a instalação dos painéis solares",
  },
  {
    type: types.TEXT,
    value: "Antes",
  },
  {
    type: types.IMAGE,
    value: "/imgs/products/antes.png",
  },
  {
    type: types.TEXT,
    value: "Depois",
  },
  {
    type: types.IMAGE,
    value: "/imgs/products/depois.png",
  },
  {
    type: types.TITLE,
    value: "NOSSOS SERVIÇOS:",
  },
  {
    type: types.TEXT,
    value:
      "- Consultoria e viabilidade do projeto;<br/>- Instalação, projeto e homologação de sistema Fotovoltaico;<br/>- Avaliação de eficiência energetica;<br/>- Monitoramento em tempo real (via App);<br/>- Manutenção preventiva.",
  },
];

const Solar: React.FC = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <HeaderSub
        iconPath="/imgs/products/solar-yellow.png"
        title="ENERGIA SOLAR<br/>FOTOVOLTAICA"
        serviceImage="/imgs/services/2.png"
      />
      <Content content={content} />
      <YellowBanner />
      <Footer />
    </div>
  );
};

export default Solar;
