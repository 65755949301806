import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-image: url("/imgs/bg-1.png");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(16, 19, 29, 0.7);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 150px;
  padding: 30px 0 0 0;
`;

export const LogoBottom = styled.img`
  height: 125px;
  margin: -50px 0px 0px 60px;
  padding: 0 0 0 0;
  @media (max-width: 1080px) {
    height: 50%;
    margin: 20px 0px 0px 0px;
    display: none;
  }
`;

export const TypeTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #f3ea27;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  padding: 30px 0px;
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #d8e0e4;
`;

export const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #d8e0e4;
`;

export const ItemMenu = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 22px;
  padding: 25px 25px 25px 25px;
  color: #29304a;
  font-weight: bold;
  @media (max-width: 1080px) {
    padding: 25px 0px 25px 0px;
    width: 100%;
    font-size: 20px;
  }
`;

export const ProductIcon = styled.img`
  width: 150px;
  padding-bottom: 20px;
`;

export const ServiceImage = styled.img`
  padding: 20px 0px;
  width: 20%;
  @media (max-width: 1080px) {
    width: 60%;
  }
`;
