/* eslint-disable no-multi-str */
import React from "react";
import About from "../../components/about";
import Banner from "../../components/banner";
import Contact from "../../components/contact";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Portfolio from "../../components/portfolio";
import Possibilities from "../../components/possibilities";
import Products from "../../components/products";
import Services from "../../components/services";
import SocialBar from "../../components/socialBar";

const Home: React.FC = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <SocialBar />
      <Header />
      <Products />
      <Banner
        banner={{
          bg: "/imgs/bg-2.png",
          text: "REDUZA SUA CONTA DE LUZ\nEM ATÉ 95%",
          call_to_action: "SOLICITAR ORÇAMENTO",
        }}
      />
      <div id="sobre" />
      <About />
      <div id="servicos" />
      <Services />
      <div style={{ paddingTop: 30 }} />
      <Portfolio
        imgFormat="jpeg"
        fontColor="#29304a"
        backgroundColor="#fff"
        borderBottom={true}
        path="/imgs/portfolio"
        count={10}
      />
      <Banner
        banner={{
          bg: "/imgs/bg-2.png",
          text: "TENHA A CONSTRUÇÃO\nDOS SEUS SONHOS",
          call_to_action: "SOLICITAR ORÇAMENTO",
        }}
      />
      <Possibilities />
      <div id="contato" />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
