import React from "react";
import {
  Container,
  Overlay,
  Menu,
  ItemMenu,
  ProductIcon,
  LogoBox,
  TypeTitle,
  Logo,
} from "./styles";
import parse from "html-react-parser";

interface HeaderProps {
  title: string;
  iconPath: string;
  serviceImage: string;
}

const navigate = (route: string) => {
  window.location.href = route;
};

const HeaderSub: React.FC<HeaderProps> = ({
  iconPath,
  title,
  serviceImage,
}) => {
  return (
    <Container>
      <Overlay>
        <LogoBox>
          <Logo src="/imgs/logo.png" />
        </LogoBox>

        <Menu>
          <ItemMenu onClick={() => navigate("/")}>Home</ItemMenu>
          <ItemMenu onClick={() => navigate("/#servicos")}>Serviços</ItemMenu>
          <ItemMenu onClick={() => navigate("/#contato")}>Contato</ItemMenu>
          <ItemMenu onClick={() => navigate("/#sobre")}>Sobre</ItemMenu>
        </Menu>
        <TypeTitle>
          <ProductIcon src={iconPath} />
          {parse(title)}
        </TypeTitle>
      </Overlay>
    </Container>
  );
};

export default HeaderSub;
