import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: #f3ea27;
`;

export const InternalContainer = styled.div`
  width: 100%;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #29304a;
`;
