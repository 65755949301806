import React from "react";
import ServiceCard from "./card";
import { Container, ServicesTitle } from "./styles";

const Services: React.FC = () => {
  interface Service {
    img: string;
    title: string;
    topics: Array<string>;
    url: string;
  }

  const services: Array<Service> = [
    {
      img: "/imgs/services/1.png",
      title: "Energia Solar Fotovoltaica",
      topics: [
        "Estudo de viabilidade homologação do projeto na concessionária",
        "Instalação e start up do sistema",
        "Monitoramento remoto em tempo real via App",
        "Manutenção preventiva",
      ],
      url: "/solar",
    },
    {
      img: "/imgs/services/2.png",
      title: "Engenharia Ambiental",
      topics: [
        "Licenciamento",
        "Investigação ambiental",
        "Remediação de áreas contaminadas",
        "Descomissionamento",
        "Gerenciamento de residuos",
      ],
      url: "/ambiental",
    },
    {
      img: "/imgs/services/3.png",
      title: "Engenharia Civil",
      topics: [
        "Projetos executivos",
        "Obras de reforma e construção cívil",
        "Construções sustentáveis",
        "Captação de água de chuva",
        "Obras de demolição",
        "Gerenciamento de obras",
      ],
      url: "/civil",
    },
  ];

  return (
    <Container>
      <ServicesTitle>PRINCIPAIS SERVIÇOS</ServicesTitle>
      {services.map((service) => (
        <ServiceCard service={service} />
      ))}
    </Container>
  );
};

export default Services;
