import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: #29304a;
`;

export const InternalContainer = styled.div`
  width: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #fff;
`;
