/* eslint-disable no-multi-str */
import React from "react";
import Content from "../../components/content";
import Footer from "../../components/footer";
import HeaderSub from "../../components/header-sub";
import Portfolio from "../../components/portfolio";
import SocialBar from "../../components/socialBar";
import YellowBanner from "../../components/yellow-banner";

enum types {
  TITLE,
  WHITETITLE,
  IMAGE,
  TEXT,
}

interface ContentTypes {
  type: types;
  value: string;
}

const content: Array<ContentTypes> = [
  {
    type: types.TITLE,
    value: "Projetos Executivos:",
  },
  {
    type: types.TEXT,
    value:
      "Conforme a necessidade de nossos clientes, executamos projetos arquitetônicos, estruturais, hidráuilicos e elétricos.",
  },
  {
    type: types.TITLE,
    value: "Reformas em geral:",
  },
  {
    type: types.TEXT,
    value:
      "Trabalhos com todos os tipos de reforma, desde uma simples repaginada da sua residência, até reformas completas com ampliação de edificação e demolições.",
  },
  {
    type: types.TITLE,
    value: "Construção Predial:",
  },
  {
    type: types.TEXT,
    value:
      "Executamos a construção de sua edificação desde a fundação até a entrega das chaves, contando sempre com projetos executivos e todo planejamento detalhado, garantindo qualidade total na execução de cada serviço.",
  },
  {
    type: types.TITLE,
    value: "Construção Sustentável:",
  },
  {
    type: types.TEXT,
    value:
      "Pensando nas gerações futuras e em nossos clientes, nossa empresa executa sua edificação com todos os segmentos sustentáveis (Água de reuso, sistemas fotovoltaicos e laje verde) solicitados, inclusive somos especialistas em projetos e instalações de sistemas fotovoltaicos.",
  },
  {
    type: types.TITLE,
    value: "Gerenciamento de Obras:",
  },
  {
    type: types.TEXT,
    value:
      "Nossa empresa atua com toda a gestão de sua obra, desde o orçamento detalhado até controle geral de sua obra, garantindo a fiscalização da obra o monitoramento de (previsto x realizado) dos cronogramas-físico-financeiro e a gestão de pessoas e suprimentos da sua obra.",
  },
  {
    type: types.TITLE,
    value: "Obras de Demolição:",
  },
  {
    type: types.TEXT,
    value:
      "Nossa empresa atua em projetos e execução das obras de demolição, garantindo aos nossos clientes toda a documentação necessária para aprovação do projeto e comprovação de descarte em bota fora credenciado.",
  },
  {
    type: types.TITLE,
    value: "NOSSOS SERVIÇOS:",
  },
  {
    type: types.TEXT,
    value:
      "- Projetos executivos;<br/>- Reformas em geral;<br/>- Construção predial;<br/>- Construção sustentável;<br/>- Gerenciamento de obras;<br/>- Obras de demolição.",
  },
];

const Civil: React.FC = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <HeaderSub
        iconPath="/imgs/products/civil-yellow.png"
        title="ENGENHARIA<br/>CIVIL"
        serviceImage="/imgs/services/2.png"
      />
      <Content content={content} />
      <Portfolio
        imgFormat="jpg"
        fontColor="#fff"
        backgroundColor="#29304a"
        borderBottom={false}
        path="/imgs/portfolio/civil"
        count={1}
      />
      <YellowBanner />
      <Footer />
    </div>
  );
};

export default Civil;
