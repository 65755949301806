import React from "react";
import { Container, Logo, LogoBottom, Menu, Overlay, ItemMenu } from "./styles";

const navigate = (route: string) => {
  window.location.href = route;
};

const Header: React.FC = () => {
  return (
    <Container>
      <Overlay>
        <Logo src="/imgs/logo.png" />
        <Menu>
          <ItemMenu onClick={() => navigate("/")}>Home</ItemMenu>
          <ItemMenu onClick={() => navigate("/#servicos")}>Serviços</ItemMenu>
          <ItemMenu onClick={() => navigate("/#contato")}>Contato</ItemMenu>
          <ItemMenu onClick={() => navigate("/#sobre")}>Sobre</ItemMenu>
        </Menu>
        <LogoBottom src="/imgs/logo.png" />
      </Overlay>
    </Container>
  );
};

export default Header;
