import React from "react";
import { Container, AboutTitle, AboutText } from "./styles";

const About: React.FC = () => {
  return (
    <Container>
      <AboutTitle>SOBRE</AboutTitle>
      <AboutText>
        A SUSTENGTEC
        <br />
        <br />
        Somos uma empresa de engenharia ambiental, civil e sustentabilidade que
        atua em projetos de energia solar fotovoltaica, gerenciamento de áreas
        contaminadas, contrução civil e construção sustentáveis.
        <br />
        Trabalhamos para atender padrões mais exigentes de saúde, segurança,
        qualidade e meio ambiente, com o objetivo de superar as expectativas dos
        clientes e exigências legais dos órgãos responsáveis. Contamos com
        profissionais de formação multidisciplinar, experiência e conhecimento
        atualizados para utilizar tecnologias inovadores e solucionar problemas
        de maneira eficaz, trabalhando com seriedade e respeito às normas
        técnicas, pessoas e ao meio ambiente.
        <br />
        Prezamos a excelência e qualidade na realização dos serviços prestados,
        com responsabilidade de cumprir todos os cronogramas e quantitativos
        fornecidos no escopo de nossas atividades, garantindo a confiabilidade e
        satisfação de nossos clientes e a melhoria contínua.
        <br />
        <br />
        HISTORIA
        <br />
        <br />A Sustengtec foi criada a partir da ideia de dois amigos de
        infância que se tornaram engenheiros e decidiram criar uma empresa de
        engenharia no segmento sustentável.
      </AboutText>
    </Container>
  );
};

export default About;
