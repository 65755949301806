import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid #f3ea27;
  color: #29304a;
`;

export const AboutTitle = styled.div`
  width: 100%;
  padding: 50px 0px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 44px;
  @media (max-width: 800px) {
    font-size: 36px;
  }
`;

export const AboutText = styled.div`
  width: 50%;
  text-align: justify;
  font-size: 24px;
  line-height: 1.6;
  padding: 0 0 60px 0;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
