import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-image: url("/imgs/bg-1.png");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 40%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 70%;
  padding: 30px 0 0 0;
  @media (max-width: 1080px) {
    height: 50%;
    display: none;
  }
`;

export const LogoBottom = styled.img`
  height: 70%;
  padding: 30px 0 0 0;
  display: none;
  @media (max-width: 1080px) {
    height: 50%;
    display: flex;
  }
`;

export const Menu = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #d8e0e4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 1080px) {
    width: 100%;
    max-width: 700px;
    justify-content: center;
  }
  @media (max-width: 700px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const ItemMenu = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 22px;
  padding: 25px 0px 25px 0px;
  color: #29304a;
  font-weight: bold;
  @media (max-width: 1080px) {
    width: 100%;
    font-size: 20px;
  }
`;
