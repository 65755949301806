import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Ambiental from "../pages/ambiental";
import Civil from "../pages/civil";
import Home from "../pages/home";
import Solar from "../pages/solar";

const Routes: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={Solar} path="/solar" exact />
        <Route component={Civil} path="/civil" exact />
        <Route component={Ambiental} path="/ambiental" exact />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
