import styled from "styled-components";

interface ContainerProps {
  url: string;
}

export const Container = styled.div<Pick<ContainerProps, "url">>`
  width: 100%;
  background-image: url(${(props) => props.url});
  background-position: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid #f3ea27;
`;

export const BannerTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.8;
  padding: 100px 0px 0px 0px;
  font-size: 44px;
  color: #fff;
  white-space: pre-wrap;
  @media (max-width: 800px) {
    font-size: 36px;
  }
`;

export const BannerButton = styled.div`
  cursor: pointer;
  padding: 15px 25px;
  background-color: #d8e0e4;
  color: #29304a;
  font-size: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 50px 0 100px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
