import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 130px 0px 60px 0px;
  border-bottom: 5px solid #f3ea27;
`;

export const InternalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  background-color: #29304a;
  text-align: center;
  border-radius: 10px;
  margin-top: -70px;
  padding: 30px 50px 30px 50px;
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  @media (max-width: 800px) {
    width: 85%;
    font-size: 24px;
    padding: 30px 10px;
  }
`;

export const PossibilitiesContainer = styled.div`
  background-color: #d8e0e4;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LogosContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 60px 0px 0px 0px;
  @media (max-width: 800px) {
    width: 90%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  max-height: 60px;
  @media (max-width: 800px) {
    max-height: 50px;
    padding: 15px 0px;
  }
`;
