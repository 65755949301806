/* eslint-disable no-multi-str */
import React from "react";
import Content from "../../components/content";
import Footer from "../../components/footer";
import HeaderSub from "../../components/header-sub";
import Portfolio from "../../components/portfolio";
import SocialBar from "../../components/socialBar";
import YellowBanner from "../../components/yellow-banner";

enum types {
  TITLE,
  WHITETITLE,
  IMAGE,
  TEXT,
}

interface ContentTypes {
  type: types;
  value: string;
}

const content: Array<ContentTypes> = [
  {
    type: types.TITLE,
    value: "Licenciamento Ambiental:",
  },
  {
    type: types.TEXT,
    value: "Projetos, atuação e acompanhamento junto aos orgãos vigentes.",
  },
  {
    type: types.TITLE,
    value: "Estudos de Passivos Ambientais:",
  },
  {
    type: types.TEXT,
    value:
      "- Diagnósticos e Investigações Ambientais;<br/>- Remediação de áreas contaminadas;<br/>- Descomissionamento industrial;<br/>- Gerenciamento de Resíduos.",
  },
  {
    type: types.TITLE,
    value: "Gerenciamento de Resíduos:",
  },
  {
    type: types.TEXT,
    value:
      "Caracterização, remoção, transporte e destinação adequada dos resíduos.",
  },
  {
    type: types.TITLE,
    value: "Gestão Ambiental:",
  },
  {
    type: types.TEXT,
    value:
      "Implantação do SGA (Sistema de Gestão Ambiental) no processo todo, que visam a aplicabilidade técnica e a sustentabilidade, para alcançar a melhoria contínua.",
  },
  {
    type: types.TITLE,
    value: "NOSSOS SERVIÇOS:",
  },
  {
    type: types.TEXT,
    value:
      "- Remediação de áreas contaminadas;<br/>- Diagnosticos e investigadores ambientais;<br/>- Gerenciamento de resíduos;<br/>- Descomissionamento industrial;<br/>- Energia solar.",
  },
];

const Ambiental: React.FC = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <HeaderSub
        iconPath="/imgs/products/ambiental-yellow.png"
        title="ENGENHARIA<br/>AMBIENTAL"
        serviceImage="/imgs/services/2.png"
      />
      <Content content={content} />
      <Portfolio
        imgFormat="jpg"
        fontColor="#fff"
        backgroundColor="#29304a"
        borderBottom={false}
        path="/imgs/portfolio/ambiental"
        count={4}
      />
      <YellowBanner />
      <Footer />
    </div>
  );
};

export default Ambiental;
